.spinner::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: calc(100% - calc(100% - 14.92px));
  height: calc(100% - calc(100% - 11px));
  background: rgb(97, 0, 255);
  top: 68px;
  left: 30px;
}

.spinner-small::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  width: calc(100% - calc(100% - 10.92px));
  height: calc(100% - calc(100% - 6.2px));
  background: rgb(97, 0, 255);
  top: 42px;
  left: 17px;
}
