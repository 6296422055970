@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fffdf8;
  margin: 0;
  font-family: 'Fjalla One';
}

*::-webkit-scrollbar {
  display: none;
}

* {
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

* {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}

.clip-path {
  background-image: linear-gradient(var(--gradient-angle), #c6c6c6 0%, #ededed 50%, #ededed 100%);
  backdrop-filter: blur(50px);
  animation: rotation 6s linear 0s infinite normal forwards;
}

.full-linear {
  width: 100% !important;
  height: 98% !important;
  margin: 1px !important;
}

.inner-clip {
  background-color: #f0f0f0;
  border-radius: 10px;
  transition: all 0.2s;
  backdrop-filter: blur(5px);
  width: 99.7%;
  height: 99.7%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes rotation {
  0% {
    --gradient-angle: 0deg;
  }
  100% {
    --gradient-angle: 360deg;
  }
}

@property --gradient-angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}

.animate-all {
  animation:
    gradient-animation 12s linear infinite,
    rotate-animation 12s linear infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 50% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

span.character-name {
  border-radius: 5px;
  padding: 3px 19px 3px 21px;
  background: #f0f0f0;
  color: #111111;
  line-height: normal;
  font-size: 12px;
  font-weight: 300;
  font-family: Poppins;
  margin: 0px 0 0px 0;
  position: relative;
  display: -webkit-inline-box;
}

span.character-name::before {
  position: absolute;
  content: '@';
  left: 7px;
  top: 50%;
  transform: translateY(-52%);
}

span.cross::after {
  position: absolute;
  content: '+';
  right: 4px;
  top: 0;
  color: #747476cc;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transform: rotate(45deg);
}

.p-component.p-inputwrapper.p-mention {
  width: 100%;
  height: 100%;
}

textarea.bg-\[\#FAFAFA\]__input {
  border-radius: 0.75rem;
  padding: 12px;
  outline: none;
}

textarea.bg-\[\#FAFAFA\]__input:focus {
  outline: 1px solid #6100ff78;
}

.bg-\[\#FAFAFA\]__suggestions {
  background-color: #fffdf8 !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 100px !important;
  border-radius: 8px;
  z-index: 999999 !important;
}
